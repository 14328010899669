import React, { useState } from "react";
import { Box, Input, Textarea, Button, Heading } from "@chakra-ui/react";

const CampaignForm = ({ onSubmit }) => {
  const [companyName, setCompanyName] = useState("");
  const [numCreators, setNumCreators] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");

  const handleSubmit = () => {
    onSubmit(companyName, additionalDetails, numCreators);
  };

  return (
    <Box maxW="700px" w="full" mb={4}>
      <Heading mb={6} textAlign="center">
        Create Campaign
      </Heading>
      <Box mb={4}>
        <Input
          type="text"
          placeholder="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </Box>
      <Box mb={4}>
        <Input
          type="number"
          placeholder="# of Influencers"
          value={numCreators}
          onChange={(e) => setNumCreators(Number(e.target.value))}
        />
      </Box>
      <Box mb={4}>
        <Textarea
          placeholder="Additional Details"
          value={additionalDetails}
          onChange={(e) => setAdditionalDetails(e.target.value)}
        />
      </Box>
      <Button colorScheme="blue" width="full" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};

export default CampaignForm;
