export async function runProjectGen(
  companyName,
  additionalDetails,
  numCreators
) {
  const url = "https://hoobe-backend.onrender.com/runProjectGen"; // Backend URL
  const data = {
    company_name: companyName,
    additional_details: additionalDetails,
    num_creators: numCreators,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return { success: true, data: responseData };
  } catch (error) {
    return { success: false, error: error.message };
  }
}
