import React, { useState } from "react";
import { ChakraProvider, Flex, Box, Heading } from "@chakra-ui/react";
import CampaignForm from "./components/CampaignForm";
import ListTable from "./ListTable";
import { runProjectGen } from "./runProjectGen";

function App() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const handleSubmit = async (companyName, additionalDetails, numCreators) => {
    setError(null);
    setData([]);

    try {
      const result = await runProjectGen(
        companyName,
        additionalDetails,
        numCreators
      );

      if (result.success) {
        setData(Array.isArray(result.data) ? result.data : []); // Ensure data is an array
      } else {
        setError(result.error);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
    }
  };

  return (
    <ChakraProvider>
      <Flex direction="column" align="center" justify="center" p={4}>
        <CampaignForm onSubmit={handleSubmit} />
        {error && (
          <Box mb={4} color="red.500">
            <Heading size="md">Error:</Heading>
            <p>{error}</p>
          </Box>
        )}
        {data.length > 0 && (
          <Box w="full">
            <ListTable creatorsData={data} />{" "}
            {/* Pass the array of influencer data */}
          </Box>
        )}
      </Flex>
    </ChakraProvider>
  );
}

export default App;
