import { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Input,
  InputGroup,
  Button,
  IconButton,
  VStack,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";

const ListTable = ({ creatorsData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = useBreakpointValue({ base: true, md: false });

  const filteredData = Array.isArray(creatorsData)
    ? creatorsData.filter((creator) =>
        (creator.name?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
        (creator.instagram_handle?.toLowerCase() || "").includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <VStack spacing={4} align="stretch" w="full">
      <HStack spacing={4} align="center" justifyContent="space-between" w="full">
        <InputGroup>
          <Input
            placeholder="Search creators..."
            size="lg"
            borderRadius="xl"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        {isMobile ? (
          <IconButton
            size="lg"
            color="white"
            bg="gray.800"
            icon={<Icon as={FiFilter} />}
            _hover={{ bg: "black" }}
            borderRadius="xl"
            onClick={() => {}}
          />
        ) : (
          <Button
            size="lg"
            color="white"
            bg="gray.800"
            leftIcon={<Icon as={FiFilter} />}
            _hover={{ bg: "black" }}
            borderRadius="xl"
            onClick={() => {}}
          >
            Sort
          </Button>
        )}
      </HStack>

      <Box
        overflowX="auto"
        borderWidth="1px"
        borderRadius="xl"
        w="full"
        maxW="calc(100vw - 20px)"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Profile Picture</Th>
              <Th>Name</Th>
              <Th>Instagram Handle</Th>
              <Th>Followers</Th>
              <Th>Engagement Rate</Th>
              <Th>Projected Reel Views</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.length > 0 ? (
              filteredData.map((creator, index) => (
                <Tr key={index}>
                  <Td>
                    <Image
                      boxSize="50px"
                      borderRadius="full"
                      src={creator.instagram_profile_info.profile_pic_url}
                      alt={creator.name || 'Profile Picture'}
                    />
                  </Td>
                  <Td>{creator.name || 'N/A'}</Td>
                  <Td>{creator.instagram_handle || 'N/A'}</Td>
                  <Td>{creator.instagram_profile_info.followers}</Td>
                  <Td>{creator.instagram_profile_info.engagement_rate}</Td>
                  <Td>{creator.instagram_profile_info.projected_reel_views}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="6">
                  <Text textAlign="center" fontWeight="bold">
                    No creators found
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </VStack>
  );
};

export default ListTable;
